import React from 'react';

//components

import Breakingnews from './Breakingnews';
import Politicsnews from './Politicsnews';
import Cardslider from './Cardslider';
import Luxuryproducts from './Luxuryproducts';
import Sportnews from './Sportnews';
import MonthStorySlider from './MonthStorySlider';
import Topstoryslider from './Topstoryslider';
import Marketnews from './Marketnews';
import Fashion from './Fashion';
import Article from './Article';
import Livenews from './Livenews';
import Coverstories from './Coverstories';


const Home = () => {

    return (
        <>
            {/* <Commoninformation /> */}
            {/* <Breakingnews /> */}
            <Coverstories />
            {/* <Livenews /> */}
            {/* <Politicsnews /> */}
            <Fashion/>
            <Article />
            {/* <Cardslider /> */}
            {/* <Luxuryproducts />
            <Sportnews /> */}
            {/* <MonthStorySlider />
            <Topstoryslider />
            <Marketnews /> */}

        </>
    )
}

export default Home