import React from 'react'
import Commoninformation from '../Pagehome/Commoninformation'
import { Link } from 'react-router-dom'

const Termscondition = () => {
  return (
    <>
      {/* <Commoninformation /> */}
      <div className='container-lg'>
         {/*breadcrunb start*/}
         <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Terms & Conditions
              </li>
            </ol>
          </nav>
          {/*breadcrunb End*/}
      </div>
      <div className='innerpages-section padding-50'>
        <div className='container-lg'>
          <div className='flex-box justify-center mb-4'>
            <div className='custom-heading  text-center '>
              Terms & Conditions
            </div>
          </div>

          <div className='inp-inner'>
            {/* <div class="custom-heading ch-medium">Terms & Conditions</div> */}
            <div className='custom-text ct-small'>By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Forbes India Limited.</div>
            <div className='custom-text ct-small'>Forbes India Limited is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.</div>
            <div className='custom-text ct-small'>The Forbes - For Carpenters app stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Forbes - For Carpenters app won’t work properly or at all.</div>
            <div className='custom-text ct-small'>The app does use third party services that declare their own Terms and Conditions.</div>
            <div className='custom-text ct-small'>You should be aware that there are certain things that Forbes will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Forbes cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.</div>
            <div className='custom-text ct-small'></div>


          </div>
        </div>
      </div>
    </>
  )
}

export default Termscondition