import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img'; // Import your images
import API_URL from '../../config/config'; // Adjust path to your API config
import Commoninformation from '../Pagehome/Commoninformation';
import Icons from '../../components/icons';
import { formatTitleForURL } from '../../function';

// Import images
import articalone from '../../img/news/articalone.webp';
import articaltwo from '../../img/news/articaltwo.png';
import articalthree from '../../img/news/articalthree.webp';
import articalfour from '../../img/news/articalfour.webp';

const Article = () => {
    const { category } = useParams(); // Get category from URL
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [ads, setAds] = useState([]);
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [currentAdIndex, setCurrentAdIndex] = useState(0);

    // Fetch trending news
    const fetchTrendingNews = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-coverstory-bottom.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}), // No body needed based on your API
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews(data.trending_news);
                setHasMore(data.trending_news.length > 0); // If there are any trending news items
            } else {
                setHasMore(false);
                setError(data.msg || 'Failed to fetch trending news.');
            }
        } catch (error) {
            setError('An error occurred while fetching trending news.');
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch ads
    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await fetch(`${API_URL}get-coverstory-top.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 }) // Sending dimensions
                });
                const data = await response.json();

                if (data.error === 0) {
                    setAds(data.ads);
                } else {
                    setError(data.msg || 'Failed to fetch advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);

    // Set up interval to change ads every 10 seconds
    

    useEffect(() => {
        fetchTrendingNews();
    }, []);


    return (
        <div className='main-artical padding-50'>
            <div className='container-lg row-gap'>
                <div className='flex-box'>
                    <div className='custom-heading ch-medium'>
                        More Coverstories
                    </div>
                    {/* <Link className='custom-heading ch-small' to="/category/politics" >
                        View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                    </Link> */}
                </div>
                <div className="article-grid">
                        {news.length === 0 ? (
                                    <div>No news available.</div>
                                ) : (
                                    news.map(newsItem => (
                                <Link to={`/detail/${(newsItem.url)}`} >
                                <div className="article-card" key={newsItem.id}>
                                    <div className="image-container">
                                    <img src={newsItem.image || Img.Market4} alt={newsItem.title} style={{ width: '100%' }} />
                                        
                                        <div className="overlaysummary"><div className="overlay-effect"></div><div className="overlay-text">Forbes</div></div>
                                    </div>
                                    <div className="article-content">
                                        
                                        <h4 className="category">{newsItem.sort_title}</h4>
                                        
                                        <h3 className="title">{newsItem.title}</h3>
                                        
                                    </div>
                                </div>
                                </Link> 
                            ))
                        )}
                            
                       </div> 
            </div>
        </div>
    );
};

export default Article