import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Commoninformation from '../Pagehome/Commoninformation';
import API_URL from "../../config/config";

const Sitemap = () => {
    const [categories, setCategories] = useState([]); 
    const [error, setError] = useState('');

useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_URL}get-category-title.php`); // Ensure API_URL is correct
                const data = await response.json();
                if (data.error === 0) {
                    setCategories(data.categories); // Adjust based on your actual API response structure
                } else {
                    setError(data.msg || 'Failed to fetch categories.');
                }
            } catch (error) {
                setError('An error occurred while fetching categories.');
            }
        };

        fetchCategories();
    }, []);


    return (
        <>
            {/* <Commoninformation /> */}
            <div className='container-lg'>
                {/*breadcrunb start*/}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Sitemap
                        </li>
                    </ol>
                </nav>
                {/*breadcrunb End*/}
            </div>

            <div className='sitemap-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center mb-4'>
                        <div className='custom-heading  text-center '>
                            Sitemap
                        </div>
                    </div>
                    <div className='flex-box mt-2 mb-2'>
                        <div className='custom-heading ch-medium '>
                            Usefull Link
                        </div>
                    </div>
                    <div className='sitemap-link-box'>
                        <ul className='sitemap-ul'>
                            <li><Link to={'/'} className='link'>Home</Link></li>
                            <li><Link to={'/Aboutus'} className='link'>About us</Link></li>
                            <li><Link to={"/Privacypolicy"} className='link'>Privacy Policy</Link></li>
                            <li><Link to={"/Termscondition"} className='link'>Terms & Conditions</Link></li>
                            <li><Link to={'/Contactus'} className='link'>Contact Us</Link></li>
                        </ul>
                    </div>

                    <div className='flex-box mt-2 mb-2'>
                        <div className='custom-heading ch-medium '>
                            Categories
                        </div>
                    </div>
                    <div className='sitemap-link-box'>
                        {error ? (
                            <p>{error}</p>
                        ) : (
                            <ul className='sitemap-ul'>
                                {categories.map((category) => (
                                    <li key={category.id}>
                                        <Link to={`/category/${category.title}`} className='link'>
                                            {category.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>  
            </div>
        </>
    );
};

export default Sitemap