import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import Commoninformation from './Commoninformation';
import { formatTitleForURL } from '../../function';

const Fashion = () => {

    const [politicsNews, setPoliticsNews] = useState([]);
    const [ads, setAds] = useState([]); // State to store advertisements
    const [currentAdIndex, setCurrentAdIndex] = useState(0); // State for current ad index
    const [error, setError] = useState(null);
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true); // New state for skeleton loader

    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    // Fetch politics news from the API
    useEffect(() => {
        const fetchPoliticsNews = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${API_URL}get-fashion-news.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({})
                });
                const data = await response.json();

                if (data.error === 0) {
                    setPoliticsNews(data.politics_news);
                } else {
                    setError(data.msg || 'Failed to fetch politics news.');
                }
            } catch (error) {
                setError('An error occurred while fetching politics news.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPoliticsNews();
    }, []);

    // Fetch ads from the API
    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 }) // Correctly sending dimensions
                });
                const data = await response.json();

                if (data.error === 0) {
                    setAds(data.ads);
                } else {
                    setError(data.msg || 'Failed to fetch advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);




    // Set up interval to change ads every 10 seconds
    useEffect(() => {
        let adInterval;
        if (ads.length > 0 && isAdVisible) {
            adInterval = setInterval(() => {
                setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
            }, 10000);
        }

        return () => clearInterval(adInterval);
    }, [ads, isAdVisible]);

    // Set up timeout to hide skeleton loader after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);


    const createNewsUrl = (url) => {
        return `/detail/${(url)}`;
    };

    const handleCopyLink = (url) => {
        const urlToCopy = createNewsUrl(url);
        navigator.clipboard.writeText(window.location.origin + urlToCopy)
            .then(() => {
                alert('Link copied to clipboard!'); // You can customize this alert
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };


    function decodeHtmlEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    return (
        <>
            <div className='main-fashion padding-50'>
                <div className='container-lg row-gap'>
                    <div className='flex-box'>
                        <div className='custom-heading ch-medium'>
                            Fashion
                        </div>
                        {/* <Link className='custom-heading ch-small' to="/category/politics" >
                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                        </Link> */}
                    </div>
                    <div className="news-messsanory">
                        {/* Main Left Section */}
                        <div className="left-section">
                            {politicsNews.length > 0 && politicsNews[0].image ? (
                                <Link to={`/detail/${(politicsNews[0].url)}`} className='nm-inner'>
                                    <img src={(politicsNews[0].image)} alt={politicsNews[0].title} className="main-image"/>
                                    <div className='nm-content'>
                                        <div className='overlay'></div>
                                        <div className="main-content">
                                            <h3>FASHION</h3>
                                            <h2>{politicsNews[0].title}</h2>
                                            <p>By Forbes News</p>
                                        </div>
                                    </div>
                                </Link>
                            ) : (
                                <div>No image available for the main news.</div>
                            )}
                        </div>

                        {/* Right Section */}
                        <div className="right-section">
                            <div class="grid-container">
                            {politicsNews.slice(1).map((newsItem) => (
                                                            <div key={newsItem.id}>

                                <Link to={`/detail/${(newsItem.url)}`} className="card">
                                    <img src={newsItem.image || Img.P1} alt={newsItem.title} className="card-image grid-item grid-item-7" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>{newsItem.title}</h3>
                                        <p>By Forbes News</p>
                                        {/* <span>15 October 2024</span> */}
                                    </div>
                                </Link>
                                </div>
                                                        ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fashion