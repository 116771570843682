import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img';
import API_URL from '../../config/config';
import Commoninformation from '../Pagehome/Commoninformation';
import Icons from '../../components/icons';
import { formatTitleForURL } from '../../function';







const Category = () => {
    const { category } = useParams();
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [ads, setAds] = useState([]);
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [currentAdIndex, setCurrentAdIndex] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);
    const [mainAds, setMainAds] = useState([]); // For 600x300 ads
    const [bannerAds, setBannerAds] = useState([]); // For 90x728 ads
    const [currentMainAdIndex, setCurrentMainAdIndex] = useState(0);
    const [currentBannerAdIndex, setCurrentBannerAdIndex] = useState(0);

    const fetchNews = async (offset) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-category.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    category,
                    offset,
                    limit: 9,
                }),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews((prevNews) => {
                    const existingNewsIds = new Set(prevNews.map(item => item.id));
                    const newNews = data.news.filter(item => !existingNewsIds.has(item.id));
                    return [...prevNews, ...newNews];
                });
                setHasMore(data.news.length === 9);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setError('An error occurred while fetching news.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (category) {
            setNews([]);
            setOffset(0);
            setLoadingSkeleton(true); // Show skeleton on category change
            fetchNews(0);
            const timeoutId = setTimeout(() => {
                setLoadingSkeleton(false);
            }, 1500);
        }
    }, [category]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100) {
                if (hasMore && !isLoading) {
                    setOffset(prevOffset => prevOffset + 8);
                }
            }

            setIsSticky(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, isLoading]);

    useEffect(() => {
        if (offset > 0) {
            fetchNews(offset);
        }
    }, [offset]);


    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    useEffect(() => {
        const fetchAds = async () => {
            try {
                const mainAdResponse = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 90, width: 728 })
                });
                const mainAdData = await mainAdResponse.json();

                if (mainAdData.error === 0) {
                    setMainAds(mainAdData.ads);
                } else {
                    setError(mainAdData.msg || 'Failed to fetch main advertisements.');
                }

                const bannerAdResponse = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 })
                });
                const bannerAdData = await bannerAdResponse.json();

                if (bannerAdData.error === 0) {
                    setBannerAds(bannerAdData.ads);
                } else {
                    setError(bannerAdData.msg || 'Failed to fetch banner advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);

    

    return (
        <>
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {capitalizeFirstLetter(category)}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className='category-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center'>
                        <div className='custom-heading text-center'>
                            <span>{capitalizeFirstLetter(category)}</span>
                        </div>
                    </div>

                    {isLoading && <div className="skeleton-loader"></div>}
                    {error && <div className='error-message'>{error}</div>}

                    <div className='main-fashion padding-50'>
                        <div className='container-lg row-gap'>
                            <div className="news-messsanory">
                                {/* Main Right Section: First News */}
                                {news.length > 0 && (
                                    <div className="right-section">
                                        <Link to={`/detail/${(news[0].url)}`} className='nm-inner'>
                                            <img
                                                src={news[0].image}
                                                alt={news[0].title}
                                                className="main-image"
                                            />
                                            <div className='nm-content'>
                                                <div className='overlay'></div>
                                                <div className="main-content">
                                                    <h3>{news[0].sort_title}</h3>
                                                    <h2>{news[0].title}</h2>
                                                    <h3>By Forbes News</h3>
                                                    {/* <span>{news[0].publish_dt}</span> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}

                                {/* Left Section: Remaining News */}
                                {news.length > 1 && (
                                    <div className="left-section">
                                        <div className="grid-container">
                                            {news.slice(1).map((item, index) => (
                                                <Link to={`/detail/${item.url}`} key={item.id} className="card">
                                                    <img src={item.image} alt={item.title} className="card-image grid-item" />
                                                     <div className="card-content">
                                                        <h4>{item.sort_title}</h4>
                                                        <h3>{item.title}</h3>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        </>
    );
};

export default Category;
